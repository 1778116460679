<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "TheWritersGuideToCopyright",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="TheWritersGuideToCopyright">
      <SuawParagraph text="<em>This informational article refers to copyright law in the United States only, and is not intended to be legal advice.</em>" />
      <SuawParagraph
        text="We know copyright is not the reason you became a writer, but whether you’re new to writing or a published author, knowing about copyright can help protect your rights. So, we’re here to help light the way on this dry subject. This summary will help you understand the basics of copyright and won’t give you too many grey hairs in the process."
      />
      <SuawHeading level="3" content="What is copyright?" />
      <SuawParagraph
        text="<a target='_blank' title='https://www.copyright.gov/help/faq/faq-general.html#what' href='https://www.copyright.gov/help/faq/faq-general.html#what'>According to the U.S. Copyright Office</a>, “Copyright is a form of protection grounded in the U.S. Constitution and granted by law for original works of authorship fixed in a tangible medium of expression. Copyright covers both published and unpublished works.”"
      />
      <SuawParagraph
        text="In layman terms, this means copyright gives you the right to specify how other people use your work. The law prohibits anyone from profiting from your work without your consent."
      />
      <SuawHeading level="3" content="What are the rights of a copyright owner?" />
      <SuawParagraph
        text="As the holder of a copyright, <a target='_blank' title='https://www.copyright.gov/circs/circ01.pdf' href='https://www.copyright.gov/circs/circ01.pdf'>you may</a>:"
      />
      <SuawParagraph
        text="<ul><li>Reproduce the work</li><li>Prepare “derivative works” (other works based on the original work)</li><li>Distribute copies of the work by sale, lease, or other transfer of ownership</li><li>Perform the work publicly</li><li>Display the work publicly</li></ul>"
      />
      <SuawHeading level="3" content="Legally, who is considered an author?" />
      <SuawParagraph
        text="Generally, you are considered to be an author <a target='_blank' title='https://www.copyright.gov/circs/circ01.pdf' href='https://www.copyright.gov/circs/circ01.pdf'>if you created the work yourself</a>."
      />
      <SuawParagraph
        text="However, there is an important exception to this rule: if you were hired to create the work, then you usually cannot claim copyright over the work. This is known as the “works made for hire” exception for claiming copyright. But otherwise, if you wrote an original work without being hired to do so, you may claim a copyright on your original work."
      />
      <SuawHeading level="3" content="What if I collaborated with other authors to create my original work?" />
      <SuawParagraph
        text="If you wrote an original work with another author or authors, then you may all own copyright over your work, provided that your contributions are inseparable and are <a target='_blank' title='http://copyright.universityofcalifornia.edu/ownership/joint-works.html' href='http://copyright.universityofcalifornia.edu/ownership/joint-works.html'>merged into a unified whole</a>."
      />
      <SuawParagraph
        text="However, if your work is distinctly separated — for example, if each person wrote a different chapter, <a target='_blank' title='http://copyright.universityofcalifornia.edu/ownership/joint-works.html' href='http://copyright.universityofcalifornia.edu/ownership/joint-works.html'>then there are two types of copyright involved</a>:"
      />
      <SuawParagraph
        text="<ol><li>Each contributor can register for a copyright for their individual work, which is known as a “<a target='_blank' href='https://www.copyright.gov/circs/circ34.pdf'>contribution to a collective work</a>.</li><li>The second type of copyright here is called <a target='_blank' href='https://copyright.universityofcalifornia.edu/ownership/joint-works.html'>collective work as a whole</a>. An individual author can register this type of copyright by obtaining permission from the copyright owners of the separate parts. The owner of the copyright in the collective work can then reproduce and distribute the individual contributions only as a part of that particular collective work.</li></ol>"
      />
      <SuawHeading level="3" content="How do I actually register for copyright and how much does it cost?" />
      <SuawParagraph
        text="The actual registration process to get a copyright can be fairly straightforward. <a target='_blank' title='https://www.copyright.gov/registration/' href='https://www.copyright.gov/registration/'>Visit this link</a> to get started."
      />
      <SuawParagraph
        text="Note that requirements include <a target='_blank' title='https://www.copyright.gov/about/fees.html' href='https://www.copyright.gov/about/fees.html'>filing fees</a> starting at $35 for simple copyrights involving a Single Application, as well as a copy of the completed manuscript."
      />
      <SuawParagraph
        text="Find the <a target='_blank' title='https://www.copyright.gov/about/fees.html' href='https://www.copyright.gov/about/fees.html'>full list of fees</a> here."
      />
      <SuawHeading level="3" content="Do I have to register my copyright?" />
      <SuawParagraph
        text="<a target='_blank' title='https://www.copyright.gov/circs/circ01.pdf' href='https://www.copyright.gov/circs/circ01.pdf'>No.</a> Technically, you own the copyright the moment you create a written work in a tangible form. For most authors, that means the moment someone can read your story or book, you own the copyright."
      />
      <SuawParagraph
        text="However, the benefit of registering your copyright is that by doing so, you create a public record. This public record <a target='_blank' title='https://www.legalzoom.com/articles/copyright-basics-what-is-a-copyright-and-why-is-it-important' href='https://www.legalzoom.com/articles/copyright-basics-what-is-a-copyright-and-why-is-it-important'>enhances the legal protection available to you</a> as an author. A copyright registration is required to bring an infringement lawsuit in federal court. Certain damage awards require registration as well, and the difference in award amounts can be significant."
      />
      <SuawParagraph
        text="If you notice an improper use of your work but have not yet registered your copyright, you might want to go ahead and <a target='_blank' title='https://legalbeagle.com/13309969-what-to-do-if-someone-steals-a-copyright-design.html' href='https://legalbeagle.com/13309969-what-to-do-if-someone-steals-a-copyright-design.html'>register your copyright as soon as possible after you notice the infringement</a> so that you can take further steps to protect your work."
      />
      <SuawHeading level="3" content="How long does copyright last?" />
      <SuawParagraph
        text="For works created on or after January 1, 1978, <a target='_blank' title='https://www.copyright.gov/circs/circ01.pdf' href='https://www.copyright.gov/circs/circ01.pdf'>copyright lasts</a> 70 years after the author’s death."
      />
      <SuawParagraph
        text="For pieces published before then, the rules get a bit trickier. If you’re working with works dating before 1978, you can learn the finer details within the United States Copyright Office’s online resource about <a target='_blank' title='https://www.copyright.gov/circs/circ15a.pdf' href='https://www.copyright.gov/circs/circ15a.pdf'>Duration of Copyright</a>."
      />
      <SuawHeading level="3" content="What kind of work does copyright law protect?" />
      <SuawParagraph
        text="Copyright law protects <a target='_blank' title='https://www.copyright.gov/help/faq/faq-general.html#what' href='https://www.copyright.gov/help/faq/faq-general.html#what'>works of authorship</a> including literary works such as short fiction, short stories, novels, nonfiction articles, poetry, newspaper articles, newspapers, magazine articles, and magazines."
      />
      <SuawHeading level="3" content="I had a great idea for a piece of writing. Can I copyright my idea?" />
      <SuawParagraph
        text="No. <a target='_blank' title='https://www.copyright.gov/circs/circ01.pdf' href='https://www.copyright.gov/circs/circ01.pdf'>Copyright law does not protect everything</a>. For example, ideas, titles, short phrases, slogans, familiar symbols, lists of ingredients, and intangible works are not protected by copyright law."
      />
      <SuawHeading level="3" content="Where can I learn more?" />
      <SuawParagraph
        text="The U.S. Copyright Office maintains extensive documentation about copyright law in the U.S. Visit their website, <a target='_blank' title='https://www.copyright.gov/' href='https://www.copyright.gov/'>www.copyright.gov</a>, to learn more."
      />
      <SuawHeading level="3" content="That’s the basics!" />
      <SuawParagraph text="Knowing the basics of copyright can help you continue writing with the security of knowing your work is protected by law." />
    </ArticleFrame>
  </SuawMainContent>
</template>
